<template>
  <div class="file-services-list">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isManySelect="true"
      :afterList="afterList"
      :operateColumnWidth="150"
      @selection-change="tableSelectOnChange"
    >
      <template #headBtnSlot>
        <v-button
          type="danger"
          text="批量删除"
          :disabled="tableSelectedRows.length <= 0"
          :loading="deletesLoading"
          @click="deletesOnClick"
        />
        <v-button text="上传" @click="uploadOnClick" />
      </template>
      <template #searchSlot>
        <v-input label="文件名称" v-model="searchParam.fileName" clearable placeholder="请输入文件名称" />
      </template>
      <template #operateSlot="scope">
        <v-button text="下载" type="text" @click="downloadOnClick(scope.row)" />
        <v-button text="删除" type="text" @click="deleteOnClick(scope.row)" />
      </template>
    </v-list>

    <v-dialog title="文件上传" v-model="uploadDiaShow" width="50%" :confirmLoading="uploadDiaOkLoading" @confirm="uploadDiaOnOk">
      <v-uploadFile v-model="fileUrl" ref="uploadFile" text="文件上传" @input="uploadOnSuccess" />
    </v-dialog>
  </div>
</template>

<script>
import { getFileServeListUrl, deleteFileServeInfoUrl } from './api.js';

export default {
  name: 'pubCapFileCenFileServicesList',
  data() {
    return {
      searchParam: {
        tenantId: '',
        fileName: ''
      },
      deletesLoading: false,
      tableUrl: getFileServeListUrl,
      tableSelectedRows: [],
      headers: [
        {
          prop: 'fileName',
          label: '文件名称',
          align: 'center'
        },
        {
          prop: 'updateTime',
          label: '上传时间',
          align: 'center'
        },
        {
          prop: 'optUser',
          label: '上传人',
          align: 'center'
        },
        {
          prop: 'fileSize',
          label: '文件大小',
          align: 'center',
          formatter: (row, prop) => {
            if (row[prop] < 1024) {
              return row[prop] + ' 字节';
            } else if (row[prop] < 1024 * 1024) {
              return +(row[prop] / 1024).toFixed(2) + ' KB';
            } else if (row[prop] < 1024 * 1024 * 1024) {
              return +(row[prop] / (1024 * 1024)).toFixed(2) + ' MB';
            } else if (row[prop] < 1024 * 1024 * 1024 * 1024) {
              return +(row[prop] / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
            } else {
              return +(row[prop] / (1024 * 1024 * 1024 * 1024)).toFixed(2) + ' TB';
            }
          }
        }
      ],
      uploadDiaShow: false,
      uploadDiaOkLoading: false,
      fileUrl: ''
    };
  },
  computed: {},
  created() {
    // if (_localStorage.getItem("userInfo")) {
    //   let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
    //   this.tenantId = userInfo.communityRelated;
    // }
    this.searchParam.tenantId = this.$store.state.app.userInfo.tenantId;
  },
  mounted() {},
  methods: {
    afterList() {
      this.$refs.list.toggleSelection();
      this.tableSelectedRows = [];
    },
    deletesOnClick() {
      if (Array.isArray(this.tableSelectedRows) && this.tableSelectedRows.length > 0) {
        this.deleteHand(this.tableSelectedRows.map(item => item.id).join(','));
      } else {
        this.$message.info('请选择需要删除的文件');
      }
    },
    uploadOnClick() {
      this.fileUrl = '';
      this.uploadDiaShow = true;
    },
    tableSelectOnChange(rows) {
      this.tableSelectedRows = rows;
    },
    downloadOnClick(row) {
      const a = document.createElement('a');
      a.href = row.fileUrl;
      a.download = row.fileName;
      a.target = '_blank';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleteOnClick(row) {
      this.deleteHand(row.id);
    },
    deleteHand(fileIds) {
      this.$confirm('确定删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = { fileIds }; // this.$qs.stringify(params)
          this.$axios
            .post(deleteFileServeInfoUrl, params)
            .then(res => {
              if (res && res.code === 200) {
                this.$message.success(res.msg);
                this.$refs.list.search();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    uploadOnSuccess(value, file, playTime) {
      this.$refs.list.search();
    },
    uploadDiaOnOk() {
      this.uploadDiaShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.file-services-list {
  box-sizing: border-box;
  height: 100%;
}
</style>
